import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';

import { computed, ref } from '@vue/composition-api';
import { DocModel } from './doc.interface';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import { useUi } from '@/module/ui';

const emptyItem: DocModel = {
  id: '',
  category: '',
  content: '',
  topic: '',
  title: '',
  uuid: ''
};

const state: BaseItemState<DocModel> = {
  items: ref([]),
  selectedItemId: ref(''),
  selectedItem: ref({
    ...emptyItem
  }),
  createdItemId: ref(''),
  createdItem: ref({
    ...emptyItem
  }),
  isLoading: ref(false)
};

// Sometimes we just need to use any so our brains don't explode
// eslint-disable-next-line
const items: any = ref([]);
// TODO: Do not explode brain; use DocModel refs here.

export function useDoc(query = ''): BaseUseItemModel<DocModel> {
  Vue.$log.debug('Loaded for query', query);
  const { waitOff, waitOn } = useUi();

  const selectItem = async (id: string) => {
    if (id) {
      const item = state.items.value.find((i) => i.id === id || i.uuid === id);
      if (item) {
        state.selectedItemId.value = item?.id ? item.id : '';
        state.selectedItem.value = item;
      }
    }
  };

  const validItem = async (item: DocModel) => {
    if (!item.category || !item.title || !item.content) {
      const errMessage = 'Item not valid. You need a category, title and some content.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const validUpdateItem = async (item: DocModel) => {
    if (!item.id && Object.keys(item).length < 2) {
      const errMessage = 'Item not valid. Need an id to update and at least one field.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const getItems = async (query = '') => {
    try {
      waitOn();
      state.isLoading.value = true;
      const url = `/doc${query ? '/query/?' + query : query}`;
      const res = await axios.get<DocModel[]>(url);
      Vue.$log.debug('Returned from doc API: ', res.data);
      items.value = res.data;
    } catch (err) {
      const errMessage = 'Error loading docs.';
      Vue.$log.error(errMessage, err);
      throw new Error(errMessage);
    } finally {
      waitOff();
      state.isLoading.value = false;
    }
  };

  const getItem = async (id: string) => {
    if (!id) {
      throw new Error('Can not get item without ID');
    }
    try {
      waitOn();
      state.isLoading.value = true;
      const res = await axios.get<DocModel[]>(`/doc/${id}`);
      Vue.$log.debug('Returned from doc API: ', res.data);
      state.items.value = res.data;
    } catch (err) {
      const errMessage = 'Error loading doc.';
      Vue.$log.error(errMessage, err);
      throw new Error(errMessage);
    } finally {
      waitOff();
      state.isLoading.value = false;
    }
  };

  const createItem = async (item: DocModel) => {
    if (await validItem(item)) {
      Vue.$log.debug('Creating new item: ', item);
      try {
        waitOn();
        state.isLoading.value = true;
        const res = await axios.post<DocModel>('/doc', item);
        if (res.data) {
          Vue.$log.debug('Successfully created item. Refreshing items.');
          state.createdItem.value = res.data;
          state.createdItemId.value = res.data.id!; // eslint-disable-line
          await getItems();
        } else {
          const errMessage = 'Invalid response creating item';
          Vue.$log.error(errMessage, res);
        }
      } catch (err) {
        const errMessage = 'Error caught creating item.';
        Vue.$log.error(errMessage, err);
        throw new Error(errMessage);
      } finally {
        waitOff();
        state.isLoading.value = false;
      }
    }
  };

  const updateItem = async (item: DocModel) => {
    if (await validUpdateItem(item)) {
      Vue.$log.debug('Updating item: ', item);
      try {
        waitOn();
        state.isLoading.value = true;
        const res = await axios.put<DocModel>('/doc');
        if (res.data) {
          Vue.$log.debug('Successfully updated item. Refreshing items.');
          state.createdItem.value = res.data;
          state.createdItemId.value = res.data.id!; // eslint-disable-line
          await getItems();
        } else {
          const errMessage = 'Invalid response creating item';
          Vue.$log.error(errMessage, res);
          throw new Error(errMessage);
        }
      } catch (err) {
        const errMessage = 'Error updating item';
        Vue.$log.error(errMessage, err);
        throw new Error(errMessage);
      } finally {
        waitOff();
        state.isLoading.value = false;
      }
    }
  };

  const deleteItem = async (id: string) => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting item: ', id);
    try {
      waitOn();
      state.isLoading.value = true;
      const res = await axios.delete<DocModel>(`/doc/${id}`);
      if (res.data) {
        Vue.$log.debug('Successfully deleted item. Refreshing items.');
        await getItems();
      } else {
        Vue.$log.error('Invalid response deleting item: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting item.', id);
    } finally {
      waitOff();
      state.isLoading.value = false;
    }
  };

  // Get items on load
  getItems(query);

  return {
    items: computed(() => items.value),
    selectedItemId: computed(() => state.selectedItemId),
    selectedItem: computed(() => state.selectedItem),
    createdItemId: computed(() => state.createdItemId),
    createdItem: computed(() => state.createdItem),
    getItem,
    getItems,
    createItem,
    updateItem,
    selectItem,
    deleteItem
  };
}
